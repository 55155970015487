body,
html {
  margin: 0;
  padding: 0;
  color: #404040;
  font-weight: 400;
  background-color: #fefefe;
  font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

body * {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  letter-spacing: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased
}

body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-thumb {
  background-color: #868e96;
  border-radius: 6px;
}

body::-webkit-scrollbar-track {
  background-color: #d7d9de;
}

:root {
  font-size: 62.5% !important;
}

a {
  text-decoration: none;
  color: #2f89a0;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #2f89a0;
}

::selection {
  color: #fff;
  background: #333;
  text-shadow: none;
}

::-moz-selection {
  color: #fff;
  background: #333;
  text-shadow: none;
}

#root {
  flex: 1;
  height: 100%;
}
